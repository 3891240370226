const sabaths = [
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2024-10-05",
    quarter: "2024Q4"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2024-10-12",
    quarter: "2024Q4"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2024-10-19",
    quarter: "2024Q4"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2024-10-26",
    quarter: "2024Q4"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2024-11-02",
    quarter: "2024Q4"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2024-11-09",
    quarter: "2024Q4"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2024-11-16",
    quarter: "2024Q4"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2024-11-23",
    quarter: "2024Q4"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2024-11-30",
    quarter: "2024Q4"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2024-12-07",
    quarter: "2024Q4"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2024-12-14",
    quarter: "2024Q4"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2024-12-21",
    quarter: "2024Q4"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2024-12-28",
    quarter: "2024Q4"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2025-01-04",
    quarter: "2025Q1"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2025-01-11",
    quarter: "2025Q1"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2025-01-18",
    quarter: "2025Q1"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2025-01-25",
    quarter: "2025Q1"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2025-02-01",
    quarter: "2025Q1"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2025-02-08",
    quarter: "2025Q1"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2025-02-15",
    quarter: "2025Q1"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2025-02-22",
    quarter: "2025Q1"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2025-03-01",
    quarter: "2025Q1"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2025-03-08",
    quarter: "2025Q1"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2025-03-15",
    quarter: "2025Q1"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2025-03-22",
    quarter: "2025Q1"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2025-03-29",
    quarter: "2025Q1"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2025-04-05",
    quarter: "2025Q2"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2025-04-12",
    quarter: "2025Q2"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2025-04-19",
    quarter: "2025Q2"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2025-04-26",
    quarter: "2025Q2"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2025-05-03",
    quarter: "2025Q2"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2025-05-10",
    quarter: "2025Q2"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2025-05-17",
    quarter: "2025Q2"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2025-05-24",
    quarter: "2025Q2"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2025-05-31",
    quarter: "2025Q2"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2025-06-07",
    quarter: "2025Q2"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2025-06-14",
    quarter: "2025Q2"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2025-06-21",
    quarter: "2025Q2"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2025-06-28",
    quarter: "2025Q2"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2025-07-05",
    quarter: "2025Q3"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2025-07-12",
    quarter: "2025Q3"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2025-07-19",
    quarter: "2025Q3"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2025-07-26",
    quarter: "2025Q3"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2025-08-02",
    quarter: "2025Q3"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2025-08-09",
    quarter: "2025Q3"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2025-08-16",
    quarter: "2025Q3"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2025-08-23",
    quarter: "2025Q3"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2025-08-30",
    quarter: "2025Q3"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2025-09-06",
    quarter: "2025Q3"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2025-09-13",
    quarter: "2025Q3"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2025-09-20",
    quarter: "2025Q3"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2025-09-27",
    quarter: "2025Q3"
  }
]

export default sabaths;